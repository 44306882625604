import React from "react"

import { graphql, Link } from "gatsby"

import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import SafeAreaWrapper from "../components/SafeAreaWrapper"

import { Slider } from "../components/Slider"

import { Download, Brochure } from "../components/Icon"

import Grid from "../components/Grid"
import Container from "../components/Container"
import { Button, TextLink } from "../components/Button"

import Hero from "../components/Hero"
import { BlueBanner } from "../components/Banner"

const CustomersPage = ({
	data: { page, customers, testimonials, stories, backgroundImage, bannerImage },
}) => {
	return (
		<Layout translucent={true}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<Hero
				title="Learn why so many companies trust us"
				backgroundImage={backgroundImage}
				tint={true}
				small={false}
				translucent={true}
			/>
			<div className="py-spacer-xl">
				<SafeAreaWrapper>
					<Slider name="testimonial-quotes" className="mb-spacer-xl">
						{testimonials.edges.map(
							({ node: { name, quote, photo, customer } }, index) => (
								<Grid withContainer={false}>
									<div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12">
										<div key={`customer-${index}`} className="flex flex-col">
											<div className="flex flex-row justify-start md:justify-center  w-full pb-30">
												<img
													title={customer.name}
													alt={customer.name}
													src={customer.logo?.localFile.publicURL}
													className="h-60"
												/>
											</div>
											<div className="grow display3 font-normal text-left md:text-center">
												{quote}
											</div>

											<div className="flex flex-row justify-start md:justify-center pt-spacer-sm w-full">
												<GatsbyImage
													alt={customer.name}
													image={getImage(photo?.localFile)}
													className="mr-12 w-[42px] h-[42px] rounded-full"
												/>
												<div className="text-sm">
													<div className="text-left font-bold">
														{name}
													</div>
													<div className="text-left">{customer.name}</div>
												</div>
											</div>
										</div>
									</div>
								</Grid>
							),
						)}
					</Slider>

					<div className="w-full bg-porcelain py-spacer-xl">
						<Container>
							<h2 className="display2 text-center mb-80">
								Trusted by over 180 ship-management-companies
							</h2>
						</Container>
						<Grid>
							<div className="col-start-2 col-end-12">
								<div className="grid grid-cols-12 gap-x-20 md:gap-x-40 gap-y-60">
									{customers.edges.map(({ node }) => {
										return (
											node.logo && (
												<div className="col-span-6 lg:col-span-4 xl:col-span-3 flex justify-center items-center overflow-hidden">
													<img
														src={node?.logo?.localFile.publicURL}
														alt={node?.name}
														title={node?.name}
														className="h-[40px] object-contain"
													/>
												</div>
											)
										)
									})}
								</div>
							</div>
						</Grid>
					</div>

					<Grid className="mt-spacer-xl pb-spacer-md">
						<h1 className="display1 col-start-1 col-end-13 xl:col-start-2 xl:col-end-12">
							Customer success stories
						</h1>
					</Grid>
					<Grid className="mb-spacer-md gap-y-60">
						{stories.edges.map(
							({ node: { cover, title, abstract, customer, document } }, index) => {
								const first = `xl:col-start-2 xl:col-span-5 xl:mt-60`
								const second = `xl:col-start-8 xl:col-span-4 xl:mt-0`
								return (
									<div
										className={`col-span-12 lg:col-span-6 flex flex-col ${
											index % 2 === 0 ? first : second
										}`}>
										<div className="w-full">
											<div className={`overflow-hidden rounded-medium`}>
												<GatsbyImage
													aspectRatio={16 / 9}
													className={`mb-20 rounded-medium w-full`}
													imgClassName="overflow-hidden rounded-medium"
													image={getImage(cover.localFile)}
													objectFit="cover"
													objectPosition="center center"
													alt={title}
													title={title}
												/>
											</div>
										</div>
										<span className="mt-20 mb-4 uppercase captionSmall text-pictonblue ">
											{customer?.name}
										</span>
										<h3 className="mt-4 mb-12 display3">{title}</h3>

										<p className="mt-0 mb-20 psmall">{abstract}</p>
										<TextLink
											to={document.localFile.publicURL}
											light={false}
											download={true}
											Icon={Download}>
											Read story
										</TextLink>
									</div>
								)
							},
						)}
					</Grid>

					<Grid className="mt-spacer-xl">
						<div className="col-span-12 xl:col-span-12 bg-porcelain rounded-large px-30 py-60 flex flex-col items-center justify-center">
							<Brochure className="text-pictonblue w-[75px] h-[75px]" />
							<span className="text-pictonblue captionBtn text-center mt-30 mb-6">
								Factsheet
							</span>
							<h3 className="text-black my-6 text-center display3">
								Users of Cloud Fleet Manager
							</h3>
							<p className="mt-6 mb-30 text-center max-w-[600px]">
								Find out why a continously increasing number of shipping companies
								select our system to manage their operations and streamline
								processes.
							</p>

							<div className="text-center">
								<a href="/Users-of-Cloud-Fleet-Manager.pdf" download>
									<Button primary={false} label="Download" />
								</a>
							</div>
						</div>
					</Grid>

					<Grid className="mt-40 mb-spacer-md gap-y-40">
						<div className="col-span-12 lg:col-span-6">
							<Link to="/hanseaticsoft">
								<div className="bg-pictonblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium">
									<span className="captionBtn text-left text-white uppercase mb-10">
										About Hanseaticsoft
									</span>
									<h2 className="display2 text-left text-white">
										Learn more about the team behind Cloud Fleet Manager
									</h2>
								</div>
							</Link>
						</div>
						<div className="col-span-12 lg:col-span-6">
							<Link to="/contact">
								<div className="bg-tealblue hover:shadow-xl duration-300 transition-color pt-60 pb-80 px-60 flex flex-col items-start rounded-medium">
									<span className="captionBtn text-left text-white uppercase mb-10">
										Say hello to us
									</span>
									<h2 className="display2 text-left text-white">
										Learn about our locations and contact our different offices
									</h2>
								</div>
							</Link>
						</div>
					</Grid>

					<BlueBanner
						className="my-spacer-xl"
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		backgroundImage: file(relativePath: { eq: "customers.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		page: strapiCustomersPage {
			seo {
				title
				description
				keywords
			}

			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}
		}

		testimonials: allStrapiTestimonial {
			edges {
				node {
					name
					quote
					photo {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 42, height: 42)
							}
						}
					}
					customer {
						name
						logo {
							name
							caption
							alternativeText
							localFile {
								publicURL
							}
						}
					}
				}
			}
		}

		customers: allStrapiCustomer(
			sort: { order: ASC, fields: name }
			filter: { logo: { mime: { eq: "image/svg+xml" } } }
		) {
			totalCount
			edges {
				node {
					name
					logo {
						name
						caption
						alternativeText
						localFile {
							publicURL
						}
					}
				}
			}
		}

		stories: allStrapiSuccessStory {
			edges {
				node {
					cover {
						localFile {
							childImageSharp {
								gatsbyImageData(height: 400, placeholder: DOMINANT_COLOR)
							}
						}
					}
					title
					abstract
					customer {
						name
					}

					document {
						localFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default CustomersPage
